<div class="card tab-links mb-4">
  <p-tabMenu [model]="tabLinks()">
    <ng-template pTemplate="item" let-item>
      <ng-container>
        <a
          [routerLink]="item.routerLink"
          routerLinkActive="active-route"
          class="p-menuitem-link tab-link"
        >
          <span [class]="item.icon"></span>
          <span class="ml-2">
            {{ item.label }}
          </span>
        </a>
      </ng-container>
    </ng-template>
  </p-tabMenu>
</div>
