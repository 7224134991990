import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-tab-links',
  standalone: true,
  imports: [RouterModule, TabMenuModule],
  templateUrl: './tab-links.component.html',
  styleUrl: './tab-links.component.scss',
})
export class TabLinksComponent {
  public tabLinks = input.required<MenuItem[]>();
}
